<messages>["./AlertError"]</messages>

<template>
  <v-alert
    :value="value"
    :dismissible="dismissible"
    transition="slide-y-transition"
    type="error">
    <v-row v-if="showMore" justify="center">
      <v-btn v-if="errors.length > maxResults" text @click="showMore=!showMore">
        {{ $t (showMore ? 'showLess': 'showMore' ) }}
      </v-btn>
    </v-row>
    <pre class="inlineCode">
      {{ computedErrors }}
      <span v-if="errors.length > maxResults && !showMore">
        ({{ $tc ('more', errors.length-maxResults, {amount: errors.length-maxResults }) }})
      </span>
    </pre>
    <v-row justify="center">
      <v-btn v-if="errors.length > maxResults" text @click="showMore=!showMore">
        {{ $t (showMore ? 'showLess': 'showMore' ) }}
      </v-btn>
    </v-row>
  </v-alert>
</template>

<script>
  export default {
    name: 'AlertError',

    props: {
      errors: {
        type: Array,
        required: true
      },
      maxResults: {
        type: Number,
        default: 5
      },
      value: {
        type: Boolean
      },
      dismissible: {
        type: Boolean
      }
    },

    data () {
      return {
        showMore: false
      }
    },

    computed: {
      computedErrors () {
        return (this.showMore
          ? this.errors
          : this.errors.slice (0, this.maxResults))
          .reduce ((acc, error) => {
            return acc === '' ? error : acc + '\n' + error
          }, '')
      }
    }
  }
</script>
