<!---
  This documentation is taken from the file 'apps/cgw/doc/accouting/bookentry_batch_format'.
  If the documentation is adapted, it should be adapted in both files.
--->
<template>
  <base-dialog
    v-model="visible"
    scrollable
    mw1
    close-on-esc>
    <template #activator="{ on }">
      <slot name="activator" :on="on">
        open
      </slot>
    </template>
    <v-card>
      <v-card-title class="text-h5">
        Documentation
      </v-card-title>
      <v-card-text>
        <article>
          <p>
            The batch file consists of lines with 12 fields in
            each line; the fields are <b class="highlight">separated by the pipe character</b> ('|').
            A line starting with a hash character ('#') is interpreted as a comment
            and ignored.
            Additionally, special 'header lines' containing field identifiers
            separated by pipe characters (see below) can be used to
            specify a new field order for subsequent lines.
            These header lines are recognized by the fact that no digit character is
            present in these lines; they must contain all 12 mandatory fields
            (in any order), but may contain additional, optional fields (see below).
          </p>
          <p>
            The following enumeration shows the mandatory fields that must appear in each
            line.
            Text in brackets indicates the field identifier and whether the field is
            mandatory or optional; optional fields may be left empty by entering an
            empty string between two pipe characters.
            The given field order is the 'default' field order (if no header line is used):

            <ol class="bullet">
              <li>
                member ID- numeric id of the CORE member which account is affected
                <b>['member_id', optional]</b>; should always be present unless
                the entry is not related to one specific CORE member (e.g., a
                registry volume discount)
              </li>

              <li>
                type of the involved object, one of: contact, domain, email, host,
                protection, watch, application
                <b>['object_type', optional (mandatory if 'object_id' is given)]</b>
              </li>

              <li>
                id (handle) or name (for domains and emails) of the involved object
                <b>['object_id', optional (mandatory if 'object_type' is given)]</b>;
                for domains, this may either be the domain name (a-labels or u-labels),
                the domain's registry reference ID (handle), or a domain name/object ID
                pair, separated by a slash ('/'); the latter can be used to reference
                deleted domains, or domains with specific IDs for which other IDs exist
              </li>

              <li>
                involved registry type (one of the registry identifiers used in the payload)
                <b>['registry_id', optional (mandatory if 'object_id' is given)]</b>;
              </li>

              <li>
                action, one of: modify, new, refund-create-failure, refund-delete, refund-renew,
                refund-transfer, renew-auto, renew-manual, transfer-in, redemption,
                refund, ns-usage, refund-ns-usage, membership, unlock, icann-fees,
                escrow, registrar-insurance, whois-operation, frontend-support, generic
                <b>['action_type', mandatory]</b>
              </li>

              <li>
                subject text for the billing, may contain arbitrary text
                <b>['subject', optional]</b>
              </li>

              <li>
                the name of the account (this is currently always "main")
                <b>['account_name', mandatory]</b>
              </li>

              <li>
                account change <b>['account_change', optional
                  (see comment)]</b>: this is the gross amount of the entry;
                it can be omitted if a net amount is specified. In this case,
                taxes depending on the payer and payee are applied. A negative
                value represents a charge, positive value a refund.
              </li>

              <li>
                valuta date (optional; for refunds, this will (if left empty)
                automatically be the date of the refunded billing, which is indicated
                by the next field, 'ref_to'). This can always be overridden, i.e. the valuta
                date from the original billing is <b>only</b> used if <b>no</b> explicit valuta date is
                given in the line from the batch file. If a valuta date is specified in the
                batch file, it will <b>always</b> take precedence.
                <b>['value_date', optional]</b>
              </li>

              <li>
                refers to (for refunds: this is the id of book entry of the refunded
                billing; this id can be obtained via the web interface)
                <b>['ref_to', optional]</b>
              </li>

              <li>
                comment (arbitrary text)
                <b>['comment', optional]</b>
              </li>
              <li>
                currency, one of: USD EUR
                <b>['currency', mandatory]</b>
              </li>
            </ol>
            <br>
            In addition to the above, the following optional fields are allowed,
            which must be specified in a customized header if they shall be used:

            <ol start="13" class="bullet">
              <li>
                the net amount for the entry; if specified, the (single) tax rate
                and amount are automatically calculated from this value and the specified
                gross amount <b>['net_amount', optional]</b>; if not specified, no net amount and
                no taxes are considered for the entry
              </li>

              <li>
                the tax name for the entry's tax; only used if a net amount is also
                specified; <b>['tax_name', optional]</b>; if not specified but a net amount
                is specified, the generic name "tax" is used for the entry's tax
              </li>

              <li>
                the payee name <b>["payee", optional]</b>: it consists of a type and an ID,
                separated by a colon. There a three types: registry, registrar and
                generic. For registries, the ID is the payload name or the internal
                numeric ID, if preceded by a "#". For a registrar, the ID is either the
                short name or again the internal numeric ID, if preceded by a "#".
                For generic payees, the ID is a predefined string. At the moment,
                the only valid values are "coreassoc" for the CORE Association, "corehub"
                for COREHub, "icann" for ICANN, and "external" for external fees (like
                registrar insurance). Please contact developers for others.
                If no payee shall be associated with the book entry, "none" can be used.
                Please do not use the payee field in general and not the "registry" payees
                in particular unless you know exactly what you are doing.
                If the payee field is not specified, the payee is the default payee.
              </li>

              <li>
                the currency autoconversion flag <b>["autoconv", optional]</b>:
                If the flag is set or if it is not present, the currency is
                converted depending on the configuration of the specific
                client. Valid values are "t", "true", "y" or "yes" for
                enabling the autoconversion and "f", "false", "n" or
                "no" for disabling it.
              </li>
            </ol>
          </p>
          <br>

          <p>
            Here is an example batch:
            <v-card flat color="grey lighten-2">
              <v-card-text>
                <ol class="code">
                  <li class="code comment">
                    # following header line specifies new field order
                  </li>
                  <li class="code">
                    comment|account_name|registry_id|value_date|subject|object_type|object_id|ref_to|action_type|account_change|member_id|currency|net_amount|tax_name
                  </li>
                  <li class="code comment">
                    # real book entry lines follow
                  </li>
                  <li class="code">
                    manual correction|main|afilias||test-subject 1|domain|knipp.info|1972|new|-11.9|39|USD|-10|VAT
                  </li>
                  <li class="code">
                    manual correction|main|afilias|2002-02-02T2:2:2.2Z|test-subject2|domain|knipp.info|1972|new|-23.8|39|USD|-20|VAT
                  </li>
                </ol>
              </v-card-text>
            </v-card>
          </p>
          <br>
          <p>
            Note that the first entry results in a valuta date taken from the referred
            payment '1972', while the second entry specifies an explicit valuta date.
            Using the 'header line' 3, the field order for subsequent lines is specified.
          </p>
        </article>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          v-t="'general.button.close'"
          text
          @click="visible= false"/>
      </v-card-actions>
    </v-card>
  </base-dialog>
</template>

<script>
  import BaseDialog from '@/app/core/components/BaseDialog'

  export default {
    components: {
      BaseDialog
    },
    data () {
      return {
        visible: false
      }
    }
  }
</script>

<style scoped>
ol.code {
  list-style: none;
  counter-reset: li;
  font-family: monospace;
}

li.code::before {
  content: counter(li)"|";
  color: grey;
  display: inline-block; width: 1em;
  margin-left: -1em
}

li.code.comment {
  font-style: italic;
}

li.code {
  counter-increment: li;
  word-break: break-all;
}

ol.bullet > li {
  margin: 1em 0;
}

b.highlight {
  color: red;
}
</style>
