<messages>["@/app/i18n/common/GenericObject", "./BookEntry"]</messages>

<template>
  <base-layout mw="1">
    <v-col cols="12">
      <v-card>
        <v-card-title primary-title>
          <div>
            <div
              v-t="'upload.title'"
              class="text-h5"/>
            <div
              v-t="'upload.intro'"
              class="cgwng-subheading"/>
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <doc>
                <template #activator="{ on }">
                  <a
                    v-t="'upload.showDocumentation'"
                    v-on="on"/>
                </template>
              </doc>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="9">
              <file-upload
                v-model="bookEntriesFileName"
                :mime-types="mimeTypes"
                text
                :label="$t ('upload.file')"
                @load="fileLoaded"/>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn
                v-t="'general.button.reset'"
                @click="resetFile"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <code-editor
                v-model="bookEntriesText"
                :lines="25"
                :placeholder="$t ('upload.text')"/>
            </v-col>
          </v-row>
          <alert-error
            :errors="error"
            :value="error.length>0"
            dismissible/>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <span
            v-if="showError" v-t="'upload.required'"
            class="red--text"/>
          <v-btn
            v-t="'general.button.reset'" text
            @click="reset"/>
          <v-btn
            v-t="'general.button.submit'" color="primary" :loading="isLoading"
            @click="submit"/>
        </v-card-actions>
      </v-card>
    </v-col>
  </base-layout>
</template>

<script>
  import {required} from 'vuelidate/lib/validators'
  import {mapActions, mapMutations} from 'vuex'

  import BaseLayout from '@/app/core/components/BaseLayout'
  import CodeEditor from '@/app/core/components/CodeEditor/CodeEditor'
  import FileUpload from '@/app/core/components/FileUpload/FileUpload'
  import AlertError from '@/app/core/components/AlertError'
  import Doc from './components/UploadBookEntriesDoc.vue'

  export default {
    name: 'UploadBookEntries',

    components: {
      BaseLayout,
      FileUpload,
      CodeEditor,
      AlertError,
      Doc
    },

    validations: {
      bookEntriesText: {required}
    },

    data () {
      return {
        bookEntriesText: '',
        bookEntriesFileName: '',
        isLoading: false,
        showError: false,
        error: [],
        mimeTypes: [
          'text/csv'
        ]
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),

      fileLoaded (e) {
        this.bookEntriesText = e
      },

      resetFile () {
        if (this.bookEntriesFileName && this.bookEntriesFileName !== '') {
          this.bookEntriesFileName = ''
        }
      },

      reset () {
        this.bookEntriesText = ''
        this.showError = false
        this.error = []
        this.resetFile ()
      },

      submit () {
        if (!this.$v.$invalid) {
          this.showError = false
          this.error = []
          this.isLoading = true

          this.fetchData ({
            op: 'account/upload-book-entries',
            params: {
              csvData: this.bookEntriesText
            },
            cb: data => {
              this.displaySuccessMessage (
                this.$tc (
                  'upload.success',
                  data.processed,
                  {amount: data.processed}))

              this.reset ()
            },
            cbError: (result) => {
              this.error = result.errorData.split ('\n')
            },
            cbFinal: () => {
              this.isLoading = false
            }
          })
        } else {
          this.showError = true
        }
      }
    }

  }
</script>
